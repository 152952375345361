import { PrimoPiano } from './primopiano';
import { Orari } from './orari';
import { ConsigliChef } from './consigliChef';
import { Piani } from '../interface';
import { Timestamp } from '@firebase/firestore-types';

export class Restaurant {
  uid: string;
  nome: string;
  sort_nome: string;
  descrizione: string;
  telefono: string;
  ristoImg: string;
  imgGallery: Array<string>;
  primoPiano: Array<PrimoPiano>;
  consigliChef: Array<ConsigliChef>;
  abbonamento: string;
  inizioAbbonamento: string;
  listaPiani: Piani[];
  indirizzo: string;
  lat: number;
  lng: number;
  orario: Orari;
  valMenu: number;
  numValMenu: number;
  valServizio: number;
  numValServizio: number;
  valPrezzo: number;
  numValPrezzo: number;
  valLocation: number;
  numValLocation: number;
  valTotale: number;
  mediaPrezzo: number;
  categoria: string;
  concatsearch: string;
  prezzoCoperto: number;
  servizio: number;
  menuTradotto: boolean;
  disabilitato: boolean;
  intStampa: number;
  chatDisabilitata: boolean;
  dataCreazione;
  comBanco: boolean;
}
